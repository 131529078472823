import React from "react";
import "./index.css";
import { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { useLocation } from "react-router-dom";

function App() {
  const [modal, setModal] = useState();
  const [rotate, setRotate] = useState(false);
  const [mode, setMode] = useState(false);
  const [placement, setPlacement] = useState("floor");
  const [qr, setQR] = useState(false);
  const [category, setCategory] = useState("");
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const myParam = queryParams.get("product"); // Replace 'myParam' with the actual query parameter name you are looking for
    const placementparams = queryParams.get("placement");
    const categoryParams = queryParams.get("category");
    const queryParams2 = new URLSearchParams(location.search);
    const myParam2 = queryParams.get("mode"); // Replace 'myParam' with the actual query parameter name you are looking for
    const placementparams2 = queryParams.get("placement");
    setModal(myParam);
    if (categoryParams) {
      setModal(categoryParams);
      setCategory(categoryParams);
      setQR(true);
      setMode(true); 

    }
    // setMode(myParam2)
    if (["floor", "wall"].includes(placementparams)) {
      setPlacement(placementparams);
    }

  }, [location.search]);

  return (
    <>
      <div
        className="container-fluid d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <div
          className="row  d-flex align-items-center justify-content-center"
          style={{ width: "100vw" }}
        >
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
          {
            qr && (
              <div id="productQR">
                <div style={{ position: "relative" }}>
                  <QRCode
                    value={window.location.origin + `/?product=${category}`}
                    renderAs="canvas"
                  />
                </div>
                <button className="btnabove" id="ar-button-2">
                  Scan to view in AR mode
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 text-center">
            {
             mode ? (
              <model-viewer
                camera-controls
                id={qr ? "model-viewr" : ""}
                ar-placement={placement}
                rotation-per-second={rotate ? "210deg" : "0deg"}
                src={`https://s3.ap-south-1.amazonaws.com/static.gemar.in/${modal}.glb`}
                ar
                ar-modes="webxr scene-viewer quick-look"
                disable-zoom
                shadow-intensity="2"
                auto-rotate
                environment-image="studio_small_09_2k.hdr"
                loading="eager"
                poster="./load.png" 
              >
                <div class="progress-bar hide" slot="progress-bar">
                  <div class="update-bar"></div>
                </div>
              </model-viewer>
            ) 
          
            : (
              <>
              <model-viewer
                ar-placement={placement}
                camera-controls
                rotation-per-second="40deg"
                src={`https://s3.ap-south-1.amazonaws.com/static.gemar.in/${modal}.glb`}
                ar
                ar-modes="webxr scene-viewer quick-look"
                disable-zoom
                shadow-intensity="2"
                auto-rotate
                environment-image="studio_small_09_2k.hdr"
              >
                <div class="progress-bar hide" slot="progress-bar">
                  <div class="update-bar"></div>
                </div>
                <button slot="ar-button" id="ar-button">
                  View in your space
                </button>
                <br />
                <button
                  onClick={() => {
                    setMode(true);
                  }}
                  id="ar-button-2"
                >
                  360 view
                </button>
                <div id="ar-prompt">
                  <img src="ar_hand_prompt.png" />
                </div>
              </model-viewer> 
              </>
            )
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default App;
